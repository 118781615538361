<template>
  <div>
    <div class="page-header">
      <div @click="goBack()" class="left">
        <i class="el-icon-arrow-left"></i>
        <span>返回</span>
      </div>
    </div>
    <div class="page-header-pla"></div>

  </div>

</template>

<script>
export default {
  name: "i-back"
}
</script>

<style lang="scss" scoped>
.page-header-pla {
  //height: 80px;
  //margin-bottom: 10px;
}

.page-header {

  display: flex;
  align-items: center;
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;

  .left {
    display: flex;
    align-items: center;
    cursor: pointer;

    > i {
      font-size: 20px;
      font-weight: bold;
    }

    span {
      margin-left: 2px;
      font-size: 18px;
      font-weight: bold;
    }
  }


}
</style>
