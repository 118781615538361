 
import router from '@/router'
import store from '@/store' 
// import getPageTitle from '@/utils/pageTitle'
import dynamicRouters from "./getRouter"
import $api from "./../api"
import Cookies from "js-cookie";
let routesWhiteList=['/login']
 
router.beforeResolve(async (to, from, next) => { 
  if(!window.localStorage.getItem('dqlist')){
    $api.get('/api/demo/getRegionList', {parent_id: -1}).then((res) => {
      window.localStorage.setItem("dqlist",JSON.stringify(res.data))
    }).catch((err) => {
      // this.apiErr(err)
      console.log(JSON.stringify(err));
    })
  }
  
  let hasToken = store.getters.getToken 
 
  if (hasToken) {
   
    if (to.path === '/login') {
      next({ path: '/' }) 
    } else {
      const hasPermissions =
        store.getters.getRouters &&
        store.getters.getRouters.length > 0
      if (hasPermissions) {
        next()
      } else {
        try { 
          let accessRoutes = [] 
          accessRoutes = await dynamicRouters()   
        
          if(to.path=='/'){
               var path = accessRoutes[0].children[0].path; 
              next({ path: path}) 
          } else{
            next({ ...to, replace: true }) 
          }
      
        } catch(res) {  
          console.log(res)
        }
      }
    }
  } else {
    console.log("getRegionList: "+hasToken)
    if (routesWhiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      // if (recordRoute) {
      //   next(`/login?redirect=${to.path}`)
      // } else {
      //   next('/login')
      // } 
    }
  }
  // document.title = getPageTitle(to.meta.title)
})
router.afterEach(() => {
   
})

 