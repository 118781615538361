<template>
  <div>
    <el-container>
      <el-header height="60px">
        <div class="header-view">
          <img src="../../assets/image/nav-title.png" class="title">

          <div class="right" @click.stop="nav_show = !nav_show">
            <img src="../../assets/image/nav-user.png" class="user">
            <span v-if="user">{{ user.nickname }}</span>
            <img src="../../assets/image/nav-down.png" class="down">
          </div>

          <div class="nav-hide" :class="{'nav-show' : nav_show}">
            <div class="nav-list">
              <div class="line" @click="onChangePassword()">
                <i-icon name="iconfont-icon-mima" color="#232323" size="20px" @click="onChangePassword()"></i-icon>
                <span>修改密码</span>
              </div>
              <div class="line" @click.stop="onLoginOut()">
                <i-icon name="iconfont-icon-tuichu" color="#232323" size="20px" @click="onLoginOut()"></i-icon>
                <span>退出登录</span>
              </div>
            </div>
          </div>
        </div>
      </el-header>
      <el-container>
        <div class="el-aside-bg">
          <el-aside width="220px">
            <!-- 左侧菜单-->
            <sidebar v-if="$store.state.routers.length"></sidebar>
          </el-aside>
        </div>

        <el-main  style=" min-width: 900px;">
          <keep-alive :include="cachedViews">
            <router-view></router-view>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
    <change-password ref="change_password"></change-password>
      <!--回到顶部-->
    <el-backtop target=".el-main"></el-backtop>
  </div>

</template>

<script>
import changePassword from "@/components/change-password";
import sidebar from "./sidebar";

export default {
  name: "index",
  components: {changePassword, sidebar},
  data() {
    return {
      nav_show: false,
    }
  },
  computed: {
    cachedViews() {
      return this.$store.state.cachedViews
    },
  },
  mounted() {
    //监听全局点击事件
    document.getElementById('app').onclick = () => {
      if (this.nav_show) this.nav_show = false
      if (this.msg_show) this.msg_show = false
    }
  },
  methods: {
    // 当点击退出登录
    onLoginOut() {
      this.$confirm('是否退出登录', '提示').then(() => {
        this.$store.commit('loginout')
      }).catch(() => {
        console.log('取消')
      })
    },
    //  当点击修改密码
    onChangePassword() {
      this.$refs.change_password.show()
    },

    // windown刷新页面
    onRefresh() {
      window.location.reload(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-header {
  background: #FFFFFF;

  .header-view {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
    .title{
      height: 28px;
    }

    h3 {
      color: #000000;
      font-size: 26px;
    }

    .right {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      margin-left: 30px;
      .user{
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      span {
        font-size: 18px;
        color: #000000;
        padding-right: 6px;
      }
      .down{
        width: 20px;
        height: 20px;
      }
    }

    .nav-hide {
      position: absolute;
      z-index: 99;
      right: 20px;
      top: 70px;
      background: #ffffff;
      border-radius: 10px;
      -moz-box-shadow: 0 2px 5px #E0E0E0; /* 老的 Firefox */
      box-shadow: 0 2px 5px #E0E0E0;
      overflow: hidden;
      transition: all 100ms;
      height: 0;
      opacity: 0;
      transform: translateY(-20px);

      .nav-list {
        width: 160px;
        padding: 10px 20px;
      }

      .line {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          padding-left: 10px;
          font-size: 16px;
          color: #232323;
          line-height: 50px;
        }

      }
    }

    .nav-show {
      opacity: 1;
      height: 120px;
      transform: translateY(0);
    }
  }
}

.el-aside-bg {
  background: #FFFFFF;
  height: calc(100vh - 80px);
}

.el-aside {
  background: #ffffff;
  height: calc(100vh - 80px);
  padding: 10px;
}


.el-container {
  height: 100vh;
  overflow-y: hidden;
  background: #EEEEEE;
}

.el-main {
  overflow: overlay;
  position: relative;
}

//.el-main::-webkit-scrollbar {
//  position: absolute;
//  /*滚动条整体样式*/
//  width: 12px; /*高宽分别对应横竖滚动条的尺寸*/
//  height: 1px;
//  background: rgb(255 255 255 / 50%);
//
//}
//
//.el-main::-webkit-scrollbar-thumb {
//  /*滚动条里面小方块*/
//  border-radius: 10px;
//  background: rgb(0 0 0 / 40%);
//}

</style>


