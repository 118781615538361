import request from "./request.js";

const api = {
    post: (path, query) => {
        return request.post(path, query);
    },
    get: (path, query) => {
        let get_query = {
            params: query
        }
        return request.get(path, get_query);
    }
}
export default api;

