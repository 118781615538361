<template>
  <i class="iconfont" :class="name" :style="getStyle" @click.stop="onCilck()"></i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#333333"
    },
    size: {
      type: String,
      default: "30px"
    }
  },
  computed: {
    getStyle() {
      return {
        color: this.color,
        fontSize: this.size
      }
    }
  },
  methods: {
    onCilck() {
      this.$emit("click")
    }
  }
}
</script>

<style>
</style>
