import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from "./config"
import api from "./api/index.js"
import base from "./mixins/index.js"

import permission from "./router/permission"



import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
let options = {
  maxSpreadZoom: 1, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
  fullscreenEl: false, //控制是否显示右上角全屏按钮
   closeEl: false, //控制是否显示右上角关闭按钮
   tapToClose: true, //点击滑动区域应关闭图库
   shareEl: false, //控制是否显示分享按钮
   zoomEl: false, //控制是否显示放大缩小按钮
   counterEl: true, //控制是否显示左上角图片数量按钮
   arrowEl: true,  //控制如图的左右箭头（pc浏览器模拟手机时）
   tapToToggleControls: true, //点击应切换控件的可见性
   clickToCloseNonZoomable: true //点击图片应关闭图库，仅当图像小于视口的大小时
};

Vue.prototype.$config = config
Vue.prototype.$api = api
Vue.mixin(base)
Vue.use(preview, options)
import ElementUI from 'element-ui';
import './assets/theme/index.css';


import VueWechatTitle from 'vue-wechat-title'

Vue.use(VueWechatTitle)

Vue.use(ElementUI);

let echarts = require('echarts')

Vue.prototype.$echarts = function (el) {
    return echarts.init(el, null, {renderer: 'svg'})
  }
import IBack from "@/components/i-back";
Vue.component('i-back', IBack)


import IIcon from "@/components/i-icon";
Vue.component('i-icon', IIcon)


import Cookies from "js-cookie";

Vue.prototype.$cookie = Cookies


// 引入element-tiptap
import {ElementTiptapPlugin} from 'element-tiptap';
// import element-tiptap 样式
import 'element-tiptap/lib/index.css';
//使用
Vue.use(ElementTiptapPlugin, {
    lang: 'zh',
});


if (process.env.NODE_ENV !== 'development') console.log = () => {
} //生产模式



Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
